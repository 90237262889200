.page-title-box h4 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
}
.card {
    margin-bottom: 24px;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08);
    box-shadow: 0 2px 4px rgba(0,0,0,.08);
}
.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}
.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.badge-soft-success {
    color: #1cbb8c;
    background-color: rgba(28,187,140,.18);
    padding: 9px 7px !important;
}
.page-title-box .breadcrumb {
    background-color: transparent;
    padding: 0;
}
.activity-wid .activity-list:before {
    content: "";
    border-left: 2px dashed rgba(86,100,210,.25);
    position: absolute;
    left: 0px;
    bottom: 0;
    top: 32px;
}
.activity-wid .activity-list {
    position: relative;
    padding: 0 0 40px 30px;
}
.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 500;
}
.activity-wid .activity-list .activity-icon {
    position: absolute;
    left: -16px;
    top: 0;
    z-index: 9;
}
.avatar-xs {
    height: 2rem;
    width: 2rem;
}
.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #5664d2;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}
.bg-soft-primary {
    background-color: rgba(86,100,210,.25)!important;
}
.rounded-circle {
    border-radius: 50%!important;
}
.activity-wid {
    margin-top: 8px;
    margin-left: 16px;
}
.simplebar-content-wrapper {
    direction: inherit;
    -webkit-box-sizing: border-box!important;
    box-sizing: border-box!important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    visibility: visible;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
    padding: 0!important;
}
.dropdown-toggle::after{display: none !important;}
.mdi-dots-vertical{color: #000000;font-size: 22px;}




