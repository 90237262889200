body {  font-family: Inter,sans-serif;}

    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 { color: #343a40; font-family: Inter,sans-serif;}
    .font-size-24 {font-size: 24px!important;}
    .text-primary { color: #5664d2!important;}
    .font-size-14 { font-size: 14px!important;}
    .font-size-11 {font-size: 11px!important;}
    .text-muted { color: #74788d!important;}
    .font-size-13 { font-size: 13px!important; }
    .font-size-16 { font-size: 16px!important;}
    .font-size-15 { font-size: 15px!important;}
    .text-success { color: #1cbb8c!important;}
#page-topbar{position: fixed; top: 0px; right: 0px; left: 0px !important; z-index: 900; background-color:#ffffff; box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;}
.navbar-header { display: flex; -webkit-box-pack: justify; justify-content: space-between; -webkit-box-align: center; height: 72px;margin: 0px auto; padding: 0px calc(12px) 0px 0px;}
.header-item span i {color: #e9ecef !important ;cursor: pointer;font-weight: 500;}
.navbar-header img{height: 56px; padding-left: 10px; object-fit: contain;}
.app-search .form-control { background-color: rgba(241, 245, 247, 0.07);  color: rgb(255, 255, 255);border: none; height: 38px;padding-left: 40px; -webkit-box-shadow: none; box-shadow: none; border-radius: 30px;}
.app-search span { position: absolute; z-index: 10;  font-size: 16px; line-height: 38px; left: 49px; top: -9px; color: #74788d;}
.form-control:focus{box-shadow: 0 0 0 0.0rem rgb(0 123 255 / 25%) !important;}
.cu_in{margin-top: 9px;margin-left: 35px;}
   /* sidebar-css */
.vertical-menu {  width: 240px; z-index: 899; background: #252b3b; bottom: 0; margin-top: 0; position: fixed; top:71px; -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08); box-shadow: 0 2px 4px rgba(0,0,0,.08);  overflow: auto;}
:focus { outline: -webkit-focus-ring-color auto 0px !important;}
.btn:focus, .btn.focus {  outline: 0;  box-shadow: 0 0 0 0.0rem rgb(0 123 255 / 25%) !important;}
#sidebar-menu { padding: 10px 0 30px 0;}
.metismenu { margin: 0;}
.metismenu li { display: block;width: 100%;}
#sidebar-menu ul li a {display: block; padding: 0.3rem 0.7rem; color: #74788d;  position: relative;  font-size: 13.3px; -webkit-transition: all .4s; transition: all .4s; font-family: Inter,sans-serif; font-weight: 500;}
#sidebar-menu ul li a:hover {
  color: #d7e4ec;
}
.home_page_sec { margin-left: 240px; overflow: hidden;}
.home_page { padding: calc(70px + 24px) calc(24px / 2) 25px calc(24px / 2);}
.vertical-menu::-webkit-scrollbar { width: 0px;}
.vertical-menu::-webkit-scrollbar:hover{ width: 9px;}
  /* Track */
  .vertical-menu:hover:-webkit-scrollbar-track {box-shadow: inset 0 0 3px grey;  border-radius: 10px;}
    /* Handle */
  .vertical-menu::-webkit-scrollbar-thumb { background: #cccccc;   border-radius: 10px;}
  /* Handle on hover */
 .simplebar-content:before,.simplebar-content:after { content: ' ';  display: table; }
.simplebar-placeholder { max-height: 100%; max-width: 100%; width: 100%; pointer-events: none; }

.metismenu a:hover{text-decoration: none;}
input::placeholder{color: #111111;font-weight: 300;font-size: 16px !important;}
input[type=text]{font-weight: 400 !important;color: #111111 !important;}
.toast-notification > span{border-radius: 4px !important;}

#mobile_lg{display: none;}
.mobile_logo_res{display: none;}


/* @media (min-width: 767px) {

} */
@media (max-width: 992px){
.vertical-menu {
    display: none;
}
.home_page_sec {
  margin-left: 0!important;
}
.mobile_logo{width: 70px !important;}
.desktop_logo{display: none !important;}
/* .mobile_logo{display: block !important;} */
#vertical-menu-btn{display: none;}
#mobile_lg{display: block;}
.mobile_logo_res{display: block;}


}
