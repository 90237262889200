.close_button{
    padding: 4px;
    border-radius: 50%;
    transition: 0.2s;
    cursor: pointer;
    text-align: center;
    line-height: 100%;
}
.close_button:hover{
    background-color: black;
    color: white;
}