.inputTag_items{
    margin-bottom: 10px;
}
.inputTag_item{
    display: inline-flex;
    background-color: rgb(237, 237, 237);
    border-radius: 999px;
    margin-right: 5px;
    padding: 8px 15px;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 5px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
}
.inputTag_item > span:nth-child(1){
    display: inline-block;
    margin-right: 15px;
    color: black;
    font-weight: 400;
}
.inputTag_item > span:nth-child(2){
    cursor: pointer;
    font-size: 10px;
    border-radius: 50%;
    padding: 1px;
}
.inputTag_item > span:nth-child(2):hover{
    background-color: black;
    color: white;
}
textarea{
    width: 100%;
    height: 35px;
    border: none;
    padding: 5px;
    border-bottom: 1px solid dodgerblue;
    resize: none;
}