.user{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10001;
    overflow: auto;
}
.user_box{
    width: 500px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 60px;
    background-color: white;
    border-radius: 15px;
    animation: animate 0.2s linear forwards;
    text-align: left !important;
    z-index: 10002;
}
@keyframes animate{
    0%{
        margin-top: 20px;
    }
    100%{
        margin-top: 60px;
    }
}
@-webkit-keyframes animate{
    0%{
        margin-top: 20px;
    }
    100%{
        margin-top: 60px;
    }
}
.user_boxHeading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}
.user_boxHeading > .MuiSvgIcon-root{
    cursor: pointer;
}
.user_boxInputs{
    padding-bottom: 10px;
}
.input_box{
    padding: 15px;
    padding-bottom: 0;
    margin-bottom: 10px;
}
.input_box > label{
    font-weight: 600;
    display: inline-block;
    margin-bottom: 4px;
}
.input_box > input{
    display: block;
    width: 100%;
    height: 35px;
    padding-left: 5px;
    border: none;
    border-bottom: 1px solid dodgerblue;
}
.button_box{
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    margin-top: 30px;
}
.button_box > button{
    border: none;
    outline: none;
    cursor: pointer;
    padding: 8px 20px;
    transition: 0.2s;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    margin-left: 10px;
}
.cancel_button{
    background-color: tomato;
    color: white;
}
.cancel_button:hover{
    background-color: red;
}
.add_button{
    background-color: lightseagreen;
    color: white;
}
.add_button:hover{
    background-color: teal;
}