.auti-custom-input-icon {
    position: absolute;
    top: 45%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 9px;
    font-size: 24px;
    color: #5664d2;
    z-index: 99;
}


.thentication-bg img{width: 100% !important;}
.custom-control {
    position: relative;
    display: block;
    min-height: 1.35rem;
    padding-left: 1.5rem;
}
.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.175rem;
    opacity: 0;
}
.custom-control-label::before, .custom-file-label, .custom-select {
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
.custom-control-label::before {
    position: absolute;
    top: .175rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #5664d2 !important;
    background-color: #5664d2 !important;
}
input:focus {
    outline-offset: 0px !important;
}
.custom-control-label::after {
    position: absolute;
    top: .175rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}





.panel-body{margin-top: 3rem;}
.panel-body:hover {
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
    transition: 0.5s ease all;
}
/* .input-group-addon{height: 42px !important;} */
.input_login{margin-bottom: 15px !important;height: 42px !important;padding: 29px 8px 27px 41px!important;}
.sigun_form{margin-top: 1.5rem;padding:20px 0px 0px;background: #ffffff;margin-left: 70px;margin-bottom: 18px;}

.sigun_form:hover{ box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
    transition: 0.5s ease all;}
    .sigun_form h2{text-align: center;}
    .sigun_form label{padding-bottom: 7px;}
